<template>
    <div>
      <h1>Recuperar Contraseña</h1>
      <form @submit.prevent="requestPasswordReset">
        <input v-model="email" type="email" placeholder="Correo electrónico" required />
        <button type="submit">Enviar enlace de restablecimiento</button>
      </form>
      <router-link to="/">Volver al inicio</router-link>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    data() {
      return {
        email: "",
      };
    },
    methods: {
        async requestPasswordReset() {
         try {
            await axios.post("https://api.fortunia.org/api/auth/request-password-reset", { email: this.email });
            alert("Enlace de restablecimiento enviado a tu correo.");
        } catch (error) {
        console.error(error);
        alert("Error al enviar el enlace de restablecimiento.");
    }
  },
    },
  };
  </script>
  