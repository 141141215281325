import { createRouter, createWebHistory } from 'vue-router';
import LoginPage from '../views/LoginPage.vue';
import AdminPanel from '../views/AdminPanel.vue';
import HomePage from '../views/HomePage.vue';
import OperationsPage from '../views/OperationsPage.vue';
import EditOperationPage from '../views/EditOperationPage.vue'; 
import UserManagement from '@/components/UserManagement.vue';
import ForgotPassword from '@/views/ForgotPassword.vue';
import VerifyCodePage from '../views/VerifyCodePage.vue';



const routes = [
  {
    path: '/',
    name: 'login',
    component: LoginPage
  },
  {
    path: '/admin',
    name: 'admin',
    component: AdminPanel,
    meta: { requiresAuth: true, isAdmin: true }
  },
  {
    path: '/admin/create',
    name: 'CreateUser',
    component: UserManagement,
    props: { form: 'create' }
  },
  {
    path: '/admin/delete',
    name: 'DeleteUser',
    component: UserManagement,
    props: { form: 'delete' }
  },
  {
    path: '/admin/change-password',
    name: 'ChangePassword',
    component: UserManagement,
    props: { form: 'changePassword' }
  },
  {
    path: '/home',
    name: 'home',
    component: HomePage
  },
  {
    path: '/operations',
    name: 'operations',
    component: OperationsPage,
    meta: { requiresAuth: true }
  },
  // Nueva ruta para la edición de operaciones
  {
    path: '/edit-order/:id', 
    name: 'EditOrder',
    component: EditOperationPage,
    meta: { requiresAuth: true, isAdmin: true }, // Asegurarse de que solo los administradores puedan acceder
    props: true // Pasar el parámetro `id` como prop al componente
  },
  {
    path: "/verify",
    name: "VerifyCodePage",
    component: VerifyCodePage,
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = localStorage.getItem('token');
  const userRole = localStorage.getItem('role');

  if (to.meta.requiresAuth && !isAuthenticated) {
    next('/');
  } else if (to.meta.isAdmin && userRole !== 'admin') {
    next('/');
  } else {
    next();
  }
});

export default router;
