<template>
  <div class="user-management">
    <h2>Gestión de Usuarios</h2>

    <!-- Menú de administración -->
    <div class="admin-menu">
      <button @click="setActiveForm('create')">Crear Usuario</button>
      <button @click="setActiveForm('delete')">Eliminar Usuario</button>
      <button @click="setActiveForm('changePassword')">Cambiar Contraseña</button>
      <button @click="setActiveForm('view')">Ver Usuarios</button>
      <button @click="setActiveForm('edit')">Modificar Usuario</button>
      <button @click="setActiveForm('resendVerification')">Reenviar Correo de Verificación</button>
      <button @click="setActiveForm('manageSubscription')">Gestionar Suscripción Premium</button>
  

    </div>




 <!-- Formulario de Gestión de Suscripción Premium -->
 <div v-if="activeForm === 'manageSubscription'">
      <h3>Gestionar Suscripción Premium</h3>
      <form @submit.prevent="manageSubscription">
        <div>
          <label for="userId">ID del Usuario:</label>
          <input v-model="subscriptionData.userId" type="number" id="userId" required />
        </div>
        <div>
          <label for="subscriptionDuration">Duración de la Suscripción (días):</label>
          <select v-model="subscriptionData.duration" id="subscriptionDuration" required>
            <option value="7">7 días</option>
            <option value="15">15 días</option>
            <option value="30">30 días</option>
          </select>
        </div>
        <div>
          <label for="activateSubscription">Activar Suscripción:</label>
          <input v-model="subscriptionData.isActive" type="checkbox" id="activateSubscription" />
        </div>
        <button type="submit">Actualizar Suscripción</button>
      </form>
      <hr />
      <form @submit.prevent="removeSubscription">
        <div>
          <label for="removeUserId">ID del Usuario para Eliminar Suscripción:</label>
          <input v-model="removeSubscriptionId" type="number" id="removeUserId" required />
        </div>
        <button type="submit">Eliminar Suscripción</button>
      </form>
    </div>    

<!-- Crear Usuario -->
<div v-if="activeForm === 'create'">
  <h3>Crear Nuevo Usuario</h3>
  <form @submit.prevent="createUser">
    <div>
      <label for="name">Nombre:</label>
      <input v-model="newUser.name" type="text" id="name" required />
    </div>
    <div>
      <label for="email">Correo Electrónico:</label>
      <input v-model="newUser.email" type="email" id="email" required />
    </div>
    <div>
      <label for="password">Contraseña:</label>
      <input v-model="newUser.password" type="password" id="password" required />
    </div>
    <div>
      <label for="role">Rol:</label>
      <select v-model="newUser.role" id="role" required>
        <option value="user">Usuario</option>
        <option value="admin">Administrador</option>
      </select>
    </div>
    <div>
      <label for="phone_number">Número de Teléfono (Opcional):</label>
      <input v-model="newUser.phone_number" type="text" id="phone_number" />
    </div>
    <div>
      <label for="receive_sms_alerts">
        ¿Recibir Notificaciones por SMS? (Opcional):
      </label>
      <input v-model="newUser.receive_sms_alerts" type="checkbox" id="receive_sms_alerts" />
    </div>
    <button type="submit" :disabled="isSubmitting">Crear Usuario</button>
  </form>
</div>


    <!-- Eliminar Usuario -->
    <div v-if="activeForm === 'delete'">
      <h3>Eliminar Usuario</h3>
      <form @submit.prevent="deleteUser">
        <div>
          <label for="userId">ID del Usuario:</label>
          <input v-model="deleteUserId" type="number" id="userId" required />
        </div>
        <button type="submit">Eliminar Usuario</button>
      </form>
    </div>

    <!-- Cambiar Contraseña -->
    <div v-if="activeForm === 'changePassword'">
      <h3>Cambiar Contraseña</h3>
      <form @submit.prevent="changePassword">
        <div>
          <label for="changePasswordId">ID del Usuario:</label>
          <input v-model="changePasswordId" type="number" id="changePasswordId" required />
        </div>
        <div>
          <label for="newPassword">Nueva Contraseña:</label>
          <input v-model="newPassword" type="password" id="newPassword" required />
        </div>
        <button type="submit">Cambiar Contraseña</button>
      </form>
    </div>


     <!-- Ver Usuarios -->
     <div v-if="activeForm === 'view'">
      <h3>Lista de Usuarios</h3>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Nombre</th>
            <th>Correo</th>
            <th>Teléfono</th>
            <th>Recibe SMS</th>
            <th>Estado</th>
            <th>Premium</th> 
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="user in users" :key="user.id">
            <td>{{ user.id }}</td>
            <td>{{ user.name }}</td>
            <td>{{ user.email }}</td>
            <td>{{ user.phone_number || 'N/A' }}</td>
            <td>{{ user.receive_sms_alerts ? 'Sí' : 'No' }}</td>
            <td>{{ user.is_verified ? 'Activo' : 'Inactivo' }}</td>
            <td>{{ user.is_premium ? 'Sí' : 'No' }}</td>
            <td>
              <button @click="editUser(user.id)">Editar</button>
              <button @click="toggleUserStatus(user.id)">{{ user.is_verified ? 'Desactivar' : 'Activar' }}</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>


     <!-- Modificar Usuario -->
     <div v-if="activeForm === 'edit'">
      <h3>Modificar Usuario</h3>
      <form @submit.prevent="updateUser">
        <div>
          <label for="userId">ID del Usuario:</label>
          <input v-model="selectedUser.id" type="number" id="userId" required />
        </div>
        <div>
          <label for="editName">Nombre:</label>
          <input v-model="selectedUser.name" type="text" id="editName" required />
        </div>
        <div>
          <label for="editEmail">Correo Electrónico:</label>
          <input v-model="selectedUser.email" type="email" id="editEmail" required />
        </div>
        <div>
          <label for="editPhone">Teléfono:</label>
          <input v-model="selectedUser.phone_number" type="text" id="editPhone" />
        </div>
        <div>
          <label for="editReceiveSms">Recibe Notificaciones por SMS:</label>
          <input v-model="selectedUser.receive_sms_alerts" type="checkbox" id="editReceiveSms" />
       </div>
        <div>
          <label for="editStatus">Estado:</label>
          <select v-model="selectedUser.is_verified" id="editStatus">
            <option value="1">Activo</option>
            <option value="0">Inactivo</option>
          </select>
       
       
        </div>

        <div>
           <label for="editPassword">Contraseña (dejar en blanco si no se desea cambiar):</label>
          <input v-model="selectedUser.password" type="password" id="editPassword" />
        </div>


        <button type="submit" :disabled="isSubmitting">Guardar Cambios</button>
      </form>
    </div>


      <!-- Reenviar Correo de Verificación -->
      <div v-if="activeForm === 'resendVerification'">
      <h3>Reenviar Correo de Verificación</h3>
      <form @submit.prevent="resendVerificationEmail">
        <div>
          <label for="userVerificationId">ID del Usuario:</label>
          <input v-model="verificationUserId" type="number" id="userVerificationId" required />
        </div>
        <button type="submit" :disabled="isSubmitting">Reenviar Correo de Verificación</button>
      </form>
    </div>




  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      activeForm: null, // Controla qué formulario se muestra
      users: [],
      newUser: {
        name: '',
        email: '',
        password: '',
        role: 'user',
        phone_number: '', // Nuevo campo
        receive_sms_alerts: false, // Nuevo campo
      },
      subscriptionData: {
        userId: null,
        duration: 7,
        isActive: false,
      },
      selectedUser: {
        id: null,
        name: '',
        email: '',
        phone_number: '',
        receive_sms_alerts: false,
        is_verified: true,
        is_premium: false, 
      },
      deleteUserId: null,
      changePasswordId: null,
      newPassword: '',
      verificationUserId: null,
      premiumDuration: 7,
      isSubmitting: false,
      removeSubscriptionId: null,
    };
  },
  methods: {
    setActiveForm(form) {
      this.activeForm = form; // Cambia el formulario activo
      if (form === 'view') {
        this.fetchUsers();
      }
    },


  async fetchUsers() {
  const token = localStorage.getItem('token');
  try {
    const response = await axios.get('https://api.fortunia.org/api/users/users', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    // Procesar los usuarios y determinar si son premium según la fecha de expiración
    this.users = response.data.map(user => {
      const currentDate = new Date();
      const premiumExpirationDate = new Date(user.premium_expiration_date);

      // Compara las fechas y asigna is_premium
      user.is_premium = premiumExpirationDate >= currentDate;

      return user;
    });
  } catch (error) {
    console.error('Error al obtener usuarios:', error);
    alert('Hubo un error al obtener los usuarios.');
  }
},



     async editUser(userId) {
     const user = this.users.find(u => u.id === userId);
       this.selectedUser = { ...user };
       // Asegúrate de que el valor de `receive_sms_alerts` sea un booleano
      this.selectedUser.receive_sms_alerts = Boolean(this.selectedUser.receive_sms_alerts);
      this.setActiveForm('edit');
    },



//Actualizacion de datos de usuarios
  async updateUser() {
  const token = localStorage.getItem('token');
  if (!token) {
    alert('No hay un token disponible. Por favor, inicia sesión nuevamente.');
    return;
  }

  // Crear una copia de los datos del usuario
  const userData = { ...this.selectedUser };

  // Si el acceso premium es activado, asignar la fecha de vencimiento basada en la duración seleccionada
  if (userData.is_premium) {
    const currentDate = new Date();
    const expirationDate = new Date(currentDate.setDate(currentDate.getDate() + this.premiumDuration)); // Sumar los días seleccionados
    userData.premium_expiration_date = expirationDate.toISOString().split('T')[0]; // Formato YYYY-MM-DD
  } else {
    // Si no es premium, asegurarse de limpiar la fecha de vencimiento
    userData.premium_expiration_date = null;
  }

  // Eliminar la contraseña si no está presente
  if (!userData.password) {
    delete userData.password; // Elimina la contraseña si no se desea actualizar
  }

  try {
    // Primero, actualiza los datos del usuario
    await axios.put(
      `https://api.fortunia.org/api/update-users/update/${this.selectedUser.id}`,
      userData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    alert('Usuario actualizado con éxito!');

   
    this.selectedUser = {}; // Limpiar los datos del usuario seleccionado
    this.activeForm = null; // Cerrar el formulario

  } catch (error) {
    console.error('Error actualizando usuario:', error);
    if (error.response) {
      alert(`Error: ${error.response.data.message || 'No se pudo actualizar el usuario.'}`);
    } else {
      alert('Hubo un error al actualizar el usuario. Revisa tu conexión.');
    }
  }
},

async manageSubscription() {
  const token = localStorage.getItem('token');
  if (!token) {
    alert('No hay un token disponible. Por favor, inicia sesión nuevamente.');
    return;
  }

  // Validar si hay un usuario y un periodo de suscripción
  if (!this.subscriptionData.userId || !this.subscriptionData.duration) {
    alert('Faltan los datos necesarios para la suscripción.');
    return;
  }

  console.log('ID de usuario:', this.subscriptionData.userId);
  console.log('Duración:', this.subscriptionData.duration);

  // Asegurarse de que duration sea un número
  const duration = parseInt(this.subscriptionData.duration, 10);
  if (![7, 15, 30].includes(duration)) {
    alert('El período debe ser 7, 15 o 30 días.');
    return;
  }

  try {
    // Hacer la solicitud POST al endpoint para asignar la suscripción premium
    const response = await axios.post(
      `https://api.fortunia.org/api/update-users/assign-premium/${this.subscriptionData.userId}`,
      {
        period: duration, // Asegurarse de enviar el periodo como un número
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    // Verifica la respuesta antes de mostrarla
    console.log('Respuesta del servidor:', response.data);

    // Si la respuesta es exitosa, mostrar mensaje y limpiar los campos
    alert(response.data.message || 'Suscripción premium asignada correctamente.');
    this.subscriptionData = { userId: null, duration: 7, isActive: false }; // Limpiar campos
  } catch (error) {
    console.error('Error asignando suscripción:', error.response?.data || error);
    alert(`Hubo un error al asignar la suscripción: ${error.response?.data?.message || error.message}`);
  }
},



async removeSubscription() {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.delete(`https://api.fortunia.org/api/update-users/remove-premium/${this.removeSubscriptionId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    // Aquí puedes manejar la respuesta, por ejemplo:
    if (response.status === 200) {
      alert('Suscripción eliminada correctamente.');
      this.removeSubscriptionId = null; // Resetea el campo después de la eliminación
    }
  } catch (error) {
    console.error('Error al eliminar la suscripción:', error);
    alert('Hubo un error al eliminar la suscripción.');
  }
},




// Método para asignar acceso premium
async assignPremiumAccess(userId, period) {
  const token = localStorage.getItem('token'); // Obtener el token de autenticación

  // Verificar que el periodo sea válido antes de hacer la solicitud
  if (![7, 15, 30].includes(period)) {
    alert('La duración del acceso premium debe ser 7, 15 o 30 días.');
    return;
  }

  try {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + period); // Establecer fecha de vencimiento

    // Realizar la solicitud para asignar el acceso premium
    await axios.put(
      `https://api.fortunia.org/api/update-users/assign-premium/${userId}`,
      {
        premium_expiration_date: expirationDate.toISOString().split('T')[0], // Fecha en formato YYYY-MM-DD
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    alert('Acceso Premium asignado correctamente.');
  } catch (error) {
    console.error('Error asignando acceso premium:', error);
    alert('Hubo un error al asignar el acceso premium.');
  }
},




    async createUser() {
      this.isSubmitting = true;
      const token = localStorage.getItem('token');
      try {
        await axios.post(
          'https://api.fortunia.org/api/users',
          {
            ...this.newUser,
            receive_sms_alerts: this.newUser.receive_sms_alerts ? 1 : 0, // Convertir checkbox a 0 o 1
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          }
        );
        alert('Usuario creado con éxito!');
        this.resetCreateUserForm();
      } catch (error) {
        console.error('Error creando usuario:', error);
        alert('Hubo un error al crear el usuario. Por favor, intenta de nuevo.');
      } finally {
        this.isSubmitting = false;
      }
    },
    resetCreateUserForm() {
      this.newUser = {
        name: '',
        email: '',
        password: '',
        role: 'user',
        phone_number: '',
        receive_sms_alerts: false,
      };
      this.activeForm = null;
    },
  
    async deleteUser() {
      const token = localStorage.getItem('token');
      try {
        await axios.delete(
          `https://api.fortunia.org/api/update-users/delete/${this.deleteUserId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        alert('Usuario eliminado con éxito!');
        this.deleteUserId = null;
      } catch (error) {
        console.error('Error eliminando usuario:', error);
        alert('Hubo un error al eliminar el usuario. Por favor, intenta de nuevo.');
      }
    },

//Funcion para enviar nuevamente el correo de verificacion de un usuario 
  async resendVerificationEmail() {
  const token = localStorage.getItem('token');
  

  try {
    await axios.post(
      `https://api.fortunia.org/api/auth/resend-verification-email/${this.verificationUserId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    alert('Correo de verificación reenviado.');
    this.verificationUserId = null; // Reinicia el ID después de enviar
  } catch (error) {
    console.error('Error reenviando correo de verificación:', error);
    alert('Hubo un error al reenviar el correo.');
  }
},



    async changePassword() {
      const token = localStorage.getItem('token');
      try {
        await axios.patch(
          `https://api.fortunia.org/api/users/${this.changePasswordId}/password`,
          { password: this.newPassword },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        alert('Contraseña cambiada con éxito!');
        this.changePasswordId = null;
        this.newPassword = '';
      } catch (error) {
        console.error('Error cambiando la contraseña:', error);
        alert('Hubo un error al cambiar la contraseña. Por favor, intenta de nuevo.');
      }
    },


    async toggleUserStatus(userId) {
    const token = localStorage.getItem('token');
    const userToUpdate = this.users.find(user => user.id === userId);
    
    // Cambiar el estado de activación (si está activo, lo desactivamos, y viceversa)
    const newStatus = userToUpdate.is_verified ? 0 : 1;

    try {
      // Usamos el endpoint de actualización del usuario solo para modificar el estado
      await axios.patch(
        `https://api.fortunia.org/api/update-users/update/${userId}`,  
        { is_verified: newStatus }, // Solo enviamos el nuevo estado
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );    
      
      // Actualizar la lista de usuarios después de modificar el estado
      userToUpdate.is_verified = newStatus;
      alert(`Usuario ${newStatus ? 'Activado' : 'Desactivado'} con éxito!`);
    } catch (error) {
      console.error('Error cambiando el estado del usuario:', error);
      alert('Hubo un error al cambiar el estado del usuario.');
    }
  },

 
  
  },
};
</script>


<style scoped>
.user-management {
  font-family: 'Roboto', sans-serif;
  padding: 20px;
  max-width: 1200px;
  margin: auto;
}

h2 {
  font-size: 1.8rem;
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

h3 {
  font-size: 1.5rem;
  color: #444;
  margin-bottom: 10px;
}

.admin-menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}

.admin-menu button {
  padding: 10px 15px;
  font-size: 1rem;
  background: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.admin-menu button:hover {
  background: #0056b3;
}

form {
  background: #f9f9f9;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

form div {
  margin-bottom: 15px;
}

form label {
  display: block;
  font-size: 0.9rem;
  margin-bottom: 5px;
  color: #555;
}

form input, form select {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 5px;
}

form button {
  background: #28a745;
  color: #fff;
  border: none;
  padding: 10px 15px;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;
}

form button:hover {
  background: #218838;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

table th, table td {
  text-align: left;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

table th {
  background: #f1f1f1;
  color: #333;
}

table tr:nth-child(even) {
  background: #fafafa;
}

@media (max-width: 768px) {
  .admin-menu {
    flex-direction: column;
    align-items: stretch;
  }

  form input, form select {
    font-size: 0.9rem;
  }

  h2, h3 {
    font-size: 1.2rem;
  }
}
</style>
