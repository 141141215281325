<template>
    <div class="admin-panel">

      <!-- Botón de Cerrar Sesión -->
      <div class="logout-container">
      <button @click="logout" class="logout-button">Cerrar Sesión</button>   
    </div> 




      <h2>Admin Panel</h2>
  
       <!-- Resumen de operaciones -->
    <div class="operations-summary">
      <h3>Resumen de Operaciones</h3>
      <div class="summary-card">
        <p>Operaciones Ganadas (Closed): <strong>{{ closedOrders.length }}</strong></p>
        <p>Operaciones Perdidas (Terminadas): <strong>{{ lostOrders.length }}</strong></p>
        <p>Operaciones Abiertas (Open): <strong>{{ openOrders.length }}</strong></p>
        <p>Promedio (Ganadas/Perdidas): 
          <strong>{{ calculateWinLossRatio }}</strong>
        </p>
      </div>
    </div>

    <!-- Menú de administración -->
    <div class="admin-menu">

      
      <button @click="showUserManagement = !showUserManagement">Gestionar Usuarios</button>
    </div>

    <!-- Mostrar el componente de gestión de usuarios -->
    <UserManagement v-show="showUserManagement" />


    <!-- Crear Usuario -->
<div v-if="showCreateUserForm">
  <h3>Crear Nuevo Usuario</h3>
  <form @submit.prevent="createUser">
    <div>
      <label for="name">Nombre:</label>
      <input v-model="newUser.name" type="text" id="name" required />
    </div>
    <div>
      <label for="email">Correo Electrónico:</label>
      <input v-model="newUser.email" type="email" id="email" required />
    </div>
    <div>
      <label for="password">Contraseña:</label>
      <input v-model="newUser.password" type="password" id="password" required />
    </div>
    <div>
      <label for="role">Rol:</label>
      <select v-model="newUser.role" id="role" required>
        <option value="user">Usuario</option>
        <option value="admin">Administrador</option>
      </select>
    </div>
    <!-- Nuevo Campo para Número de Teléfono -->
    <div>
      <label for="phone_number">Número de Teléfono (Opcional):</label>
      <input v-model="newUser.phone_number" type="text" id="phone_number" />
    </div>
    <!-- Nuevo Campo para Recibir Notificaciones SMS -->
    <div>
      <label for="receive_sms_alerts">
        ¿Recibir Notificaciones por SMS? (Opcional):
      </label>
      <input v-model="newUser.receive_sms_alerts" type="checkbox" id="receive_sms_alerts" />
    </div>
    <button type="submit">Crear Usuario</button>
  </form>
</div>





    <!-- Eliminar Usuario -->
    <div v-if="showDeleteUserForm">
      <h3>Eliminar Usuario</h3>
      <form @submit.prevent="deleteUser">
        <div>
          <label for="userId">ID del Usuario:</label>
          <input v-model="deleteUserId" type="number" id="userId" required />
        </div>
        <button type="submit">Eliminar Usuario</button>
      </form>
    </div>

    <!-- Cambiar Contraseña -->
    <div v-if="showChangePasswordForm">
      <h3>Cambiar Contraseña</h3>
      <form @submit.prevent="changePassword">
        <div>
          <label for="changePasswordId">ID del Usuario:</label>
          <input v-model="changePasswordId" type="number" id="changePasswordId" required />
        </div>
        <div>
          <label for="newPassword">Nueva Contraseña:</label>
          <input v-model="newPassword" type="password" id="newPassword" required />
        </div>
        <button type="submit">Cambiar Contraseña</button>
      </form>
    </div>
  
     

     <!-- Contenedor de Pestañas -->
     <div class="tabs">
      <button @click="activeTab = 'open'" :class="{ active: activeTab === 'open' }">Operaciones Abiertas</button>
      <button @click="activeTab = 'closed'" :class="{ active: activeTab === 'closed' }">Operaciones Ganadas</button>
      <button @click="activeTab = 'lost'" :class="{ active: activeTab === 'lost' }">Operaciones Perdidas</button>
      <button @click="activeTab = 'contactForm'" :class="{ active: activeTab === 'contactForm' }">Formulario de Correo</button>
   
    </div>

<!-- Mostrar el formulario de contacto -->
<div v-if="activeTab === 'contactForm'">
  <h3>Formulario de Correo</h3>
  <form @submit.prevent="sendEmail">
    <div>
      <label for="subject">Asunto:</label>
      <input v-model="email.subject" type="text" id="subject" placeholder="Asunto del correo" />
    </div>
    <div>
      <label for="message">Mensaje:</label>
      <textarea v-model="email.message" id="message" required></textarea>
    </div>
    <div>
      <label for="audience">Destinatarios:</label>
      <label>
        <input type="checkbox" v-model="email.isPremium" />
        Para Usuarios Premium
      </label>
      <p v-if="email.isPremium">Este correo será enviado solo a usuarios Premium.</p>
    </div>
    <button type="submit" :disabled="isSubmitting">Enviar Correo</button>
  </form>
  <p v-if="emailStatus">{{ emailStatus }}</p> <!-- Mostrar estado del envío -->
</div>


     
      <!-- Mostrar órdenes abiertas -->
      <div v-if="activeTab === 'open'">   
      <div v-if="openOrders.length === 0">No open orders available</div>
      <div v-if="openOrders.length > 0">
        <h3>Ordenes Abiertas</h3>
        <div class="order-cards">
          <div v-for="order in openOrders" :key="order.id" class="order-card">
            <h4>
        <!-- Enlace al activo en Finviz -->
        <a 
          :href="'https://finviz.com/quote.ashx?t=' + order.asset" 
          target="_blank" 
          rel="noopener noreferrer">
          {{ order.asset }}
        </a> 
        - {{ order.status }}
      </h4>

      <p v-if="order.company_name" class="company-name">
            {{ order.company_name }}
      </p>

            <p>Tipo de Orden: {{ order.type }}</p>
            <p>Precio Minimo de Compra: {{ order.entry_min }}</p>
            <p>Precio Maximo de Compra: {{ order.entry_max }}</p>
            <p>Salir en Perdida: {{ order.stop_loss }}</p>
            <p>Precio Objetivo 1 (+0.20): {{ order.target_price_1 }}</p>
            <p>Precio Objetivo 2 (+0.30): {{ order.target_price_2 }}</p>
            <p>Fecha de Inicio: {{ formatDate(order.start_date) }}</p>  
            <p v-if="order.comment">Notas de la Operacion: {{ order.comment }}</p>
            <button @click="editOrder(order.id)">Editar</button>
          </div>
        </div>
      </div>
    </div>

      <!-- Mostrar órdenes cerradas -->

      <div v-if="activeTab === 'closed'">

      <div v-if="closedOrders.length > 0">
        <h3>Ordenes Completadas con Exito</h3>
        <div class="order-cards">
          <div v-for="order in closedOrders" :key="order.id" class="order-card">
            <h4>
        <!-- Enlace al activo en Finviz -->
        <a 
          :href="'https://finviz.com/quote.ashx?t=' + order.asset" 
          target="_blank" 
          rel="noopener noreferrer">
          {{ order.asset }}
        </a> 
        - {{ order.status }}
      </h4>

      <p v-if="order.company_name" class="company-name">
            {{ order.company_name }}
      </p>

            <p>Tipo de Orden: {{ order.type }}</p>
            <p>Precio Minimo de Compra: {{ order.entry_min }}</p>
            <p>Precio Maximo de Compra: {{ order.entry_max }}</p>
            <p>Salir en Perdida: {{ order.stop_loss }}</p>
            <p>Precio Objetivo 1 (+0.20): {{ order.target_price_1 }}</p>
            <p>Precio Objetivo 2 (+0.30): {{ order.target_price_2 }}</p>
            <p>Fecha de Inicio: {{ formatDate(order.start_date) }}</p>  
            <p>Fecha de Ejecucion: {{ formatDate(order.end_date) }}</p>  
            <p v-if="order.comment">Notas de la Operacion: {{ order.comment }}</p>
          </div>
        </div>
      </div>
   </div>

      <!-- Mostrar órdenes perdidas (terminadas) -->

  <div v-if="activeTab === 'lost'">   
    <div v-if="lostOrders.length > 0">
      <h3>Órdenes Perdidas</h3>
      <div class="order-cards">
        <div v-for="order in lostOrders" :key="order.id" class="order-card">
          <h4>
        <!-- Enlace al activo en Finviz -->
        <a 
          :href="'https://finviz.com/quote.ashx?t=' + order.asset" 
          target="_blank" 
          rel="noopener noreferrer">
          {{ order.asset }}
        </a> 
        - {{ order.status }}
      </h4>

      <p v-if="order.company_name" class="company-name">
            {{ order.company_name }}
      </p>


          <p>Tipo de Orden: {{ order.type }}</p>
          <p>Precio Mínimo de Compra: {{ order.entry_min }}</p>
          <p>Precio Máximo de Compra: {{ order.entry_max }}</p>
          <p>Salir en Pérdida: {{ order.stop_loss }}</p>
          <p>Precio Objetivo 1 (+0.20): {{ order.target_price_1 }}</p>
          <p>Precio Objetivo 2 (+0.30): {{ order.target_price_2 }}</p>
          <p>Fecha de Inicio: {{ formatDate(order.end_date) }}</p>
          <p>Fecha de Perdida: {{ formatDate(order.start_date) }}</p>  
          <p v-if="order.comment">Notas de la Operación: {{ order.comment }}</p>
        </div>
      </div>
    </div>
  </div>

  
    <!-- Botón para iniciar la creación de orden -->
    <button @click="startAddingOrder" class="create-new-order">Crear Nueva Orden</button>

  
      <!-- Formulario para agregar una orden -->
      <div v-if="isAddingOrder">
        <h3>Agregar una nueva Operacion</h3>
        <form @submit.prevent="submitNewOrder">
          <div>
            <label for="asset">Activo:</label>
            <input v-model="newOrder.asset" type="text" id="asset" required />
          </div>
          <div>
            <label for="type">Tipo:</label>
            <select v-model="newOrder.type" id="type" required>
              <option value="buy">Comprar</option>
              <option value="sell">Vender</option>
            </select>
          </div>
          <div>
            <label for="entry_min">Entrada Mínima:</label>
            <input v-model.number="newOrder.entry_min" type="number" step="0.01" id="entry_min" required />
          </div>
          <div>
            <label for="entry_max">Entrada Máxima:</label>
            <input v-model.number="newOrder.entry_max" type="number" step="0.01" id="entry_max" required />
          </div>
          <div>
            <label for="stop_loss">Stop Loss:</label>
            <input v-model.number="newOrder.stop_loss" type="number" step="0.01" id="stop_loss" />
          </div>

          <!-- 
          <div>
            <label for="target_price_1">Target Price 1:</label>
            <input v-model.number="newOrder.target_price_1" type="number" step="0.01" id="target_price_1" />
          </div>
          <div>
            <label for="target_price_2">Target Price 2:</label>
            <input v-model.number="newOrder.target_price_2" type="number" step="0.01" id="target_price_2" />
          </div>

           -->


          <div>
            <label for="status">Status:</label>
            <select v-model="newOrder.status" id="status" required>
              <option value="open">Open</option>
              <option value="closed">Closed</option>
              <option value="terminada">Perdida</option>
            </select>
          </div>
          
          <div>
            <label for="start_date">Start Date:</label>
            <input v-model="newOrder.start_date" type="date" id="start_date" required />
          </div>
          <div>
            <label for="comment">Comentarios de la operacion:</label>
            <textarea v-model="newOrder.comment" id="comment"></textarea>
          </div>
          <button type="submit" :disabled="isOrderSubmitting">Submit</button>
          <button type="button" @click="cancelAddingOrder">Cancel</button>
        </form>
      </div>
  


   <!-- Pie de página -->
    <footer class="footer">
      <p>Fortunia 2024 Todos los Derechos Reservados</p>
      <p>Creado por <strong>Coss Consulting Group</strong></p>
      <nav>
        <ul>
          <li><a href="/privacy.html">Política de Privacidad</a></li>
          <li><a href="/disclaimer.html">Disclaimers</a></li>
          <li><a href="/about.html">Acerca de</a></li>
        </ul>
      </nav>
    </footer>

  
    


  </div>

  </template>

<!--Programacion de las funciones-->


<script>
import axios from "axios";
import UserManagement from '@/components/UserManagement.vue';

export default {
  components: {
    UserManagement,
  },
  data() {
    return {
      openOrders: [],
      closedOrders: [],
      lostOrders: [],
      activeTab: 'open',
      email: {
        subject: 'Noticias de Mercado', // Asunto predeterminado
        message: '', // Mensaje vacío por defecto
        isPremium: false,
      },
      isSubmitting: false,
      emailStatus: '', // Para mostrar el estado del envío de correo
    
      showCreateUserForm: false,
      showDeleteUserForm: false,
      showChangePasswordForm: false,
      showUserManagement: false,
      newUser: {
        name: '',
        email: '',
        password: '',
        role: 'user',
        phone_number: '', // Nuevo campo
        receive_sms_alerts: false, // Nuevo campo
      },
      deleteUserId: null,
      changePasswordId: null,
      newPassword: '',
      
      isAddingOrder: false, // Controla la visibilidad del formulario de creación de orden
      newOrder: {
        asset: '',
        type: 'buy',
        entry_min: null,
        entry_max: null,
        stop_loss: null,
        status: 'open',
        start_date: '',
        comment: '',
      },
      isOrderSubmitting: false, // Deshabilita el botón de submit mientras se procesa
    };
  },

  computed: {
    calculateWinLossRatio() {
      if (this.closedOrders.length === 0 && this.lostOrders.length === 0) {
        return "N/A";
      }
      return (this.closedOrders.length / (this.lostOrders.length || 1)).toFixed(2);
    },
  },
  created() {
    this.fetchOrders();
  },
  methods: {

    async sendEmail() {
      if (!this.email.message.trim()) {
        this.emailStatus = "El mensaje no puede estar vacío.";
        return;
      }

      if (!this.email.subject) {
        this.email.subject = 'Noticias de Mercado'; // Asunto por defecto
      }

      this.isSubmitting = true;  // Bloqueamos el botón
      this.emailStatus = '';     // Limpiamos el mensaje de estado

      try {
        const token = localStorage.getItem('token'); // Asegúrate de tener el token almacenado
        if (!token) {
          throw new Error('No estás autenticado.');
        }

        const audience = this.email.isPremium ? 'premium' : 'all'; // Determinar la audiencia

        const response = await fetch('https://api.fortunia.org/api/users/send-bulk-email', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`, // Enviar el token en el encabezado
          },
          body: JSON.stringify({
            subject: this.email.subject, // Usar la propiedad correcta del objeto email
            message: this.email.message,  // Usar la propiedad correcta del objeto email
            audience: audience,
          }),
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || 'Error al enviar correos.');
        }

        const data = await response.json();
        this.emailStatus = data.message || "Correos enviados con éxito.";
        this.email.message = ''; // Limpiar mensaje
        this.email.subject = ''; // Limpiar asunto
      } catch (error) {
        this.emailStatus = error.message || "Hubo un problema al enviar los correos.";
      } finally {
        this.isSubmitting = false; // Asegúrate de que se actualice el estado después de procesar
      }
    },







    async fetchOrders() {
        try {
          const response = await axios.get("https://api.fortunia.org/api/operations", {
            headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
          });

          if (response.data && Array.isArray(response.data)) {
            // Ordenar órdenes abiertas por fecha de inicio (de más nuevas a más antiguas)
            this.openOrders = response.data
              .filter((order) => order.status === "open")
              .sort((a, b) => new Date(b.start_date) - new Date(a.start_date));

            // Ordenar órdenes cerradas según el criterio
            this.closedOrders = response.data
              .filter((order) => order.status === "closed")
              .sort((a, b) => {
                const endDateDiff = new Date(b.end_date) - new Date(a.end_date);
                if (endDateDiff !== 0) return endDateDiff; // Primero por `end_date`
                return new Date(b.start_date) - new Date(a.start_date); // Luego por `start_date`
              });
          }

          // Obtener y ordenar las órdenes terminadas (perdidas)
          const lostResponse = await axios.get("https://api.fortunia.org/api/operations/terminada", {
            headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
          });

          if (lostResponse.data && Array.isArray(lostResponse.data)) {
            // Ordenar órdenes terminadas según el mismo criterio que las cerradas
            this.lostOrders = lostResponse.data.sort((a, b) => {
              const endDateDiff = new Date(b.end_date) - new Date(a.end_date);
              if (endDateDiff !== 0) return endDateDiff; // Primero por `end_date`
              return new Date(b.start_date) - new Date(a.start_date); // Luego por `start_date`
            });
          }

        } catch (error) {
          console.error("Error fetching orders", error);
          alert("Unable to fetch orders. Please try again later.");
        }
      },

    startAddingOrder() {
      this.isAddingOrder = true;
    },
    cancelAddingOrder() {
      this.isAddingOrder = false;
      this.resetNewOrder();
    },
    resetNewOrder() {
      this.newOrder = {
        asset: "",
        type: "buy",
        entry_min: null,
        entry_max: null,
        stop_loss: null,       
        status: "open",
        start_date: "",
        comment: "",
      };
    },

 
    async submitNewOrder() {
  this.isOrderSubmitting = true; // Deshabilitar el botón de submit mientras se procesa

  // Verificar si los campos necesarios están completos
  if (!this.newOrder.asset || !this.newOrder.entry_min || !this.newOrder.entry_max || !this.newOrder.start_date) {
    alert("Por favor, complete todos los campos requeridos.");
    this.isOrderSubmitting = false;
    return;
  }

  try {
    // Enviar la solicitud POST para crear la nueva orden
    const response = await axios.post("https://api.fortunia.org/api/operations", this.newOrder, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });

    // Verificar si la respuesta es válida y agregar la nueva orden
    if (response.data) {
      this.openOrders.push(response.data); // Añadir la nueva orden a la lista
      this.cancelAddingOrder(); // Cerrar el formulario después de enviar
      alert("Orden creada exitosamente");
    }
  } catch (error) {
    console.error("Error creando la nueva orden:", error);
    alert("Error al crear la orden. Intenta de nuevo más tarde.");
  } finally {
    this.isOrderSubmitting = false; // Rehabilitar el botón de submit después de procesar
  }
},









    editOrder(id) {
      this.$router.push(`/edit-order/${id}`);
    },
    formatDate(date) {
      const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
      return new Date(date).toLocaleDateString('en-US', options);
    },


   // Métodos para la administración de usuarios
async createUser() {
  try {
    await axios.post('https://api.fortunia.org/api/users', this.newUser, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
    alert('Usuario creado exitosamente');
    this.showCreateUserForm = false;
    this.fetchUsers();
  } catch (error) {
    console.error("Error creating user", error);
    alert('Error al crear usuario');
  }
},

async deleteUser() {
  try {
    await axios.delete(`https://api.fortunia.org/api/users/${this.deleteUserId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
    alert('Usuario eliminado exitosamente');
    this.showDeleteUserForm = false;
    this.fetchUsers();
  } catch (error) {
    console.error("Error deleting user", error);
    alert('Error al eliminar usuario');
  }
},

async changePassword() {
  try {
    await axios.put(
      `https://api.fortunia.org/api/users/${this.changePasswordId}/password`,
      { password: this.newPassword },
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    alert('Contraseña cambiada exitosamente');
    this.showChangePasswordForm = false;
  } catch (error) {
    console.error('Error cambiando la contraseña', error);
    alert('Error al cambiar contraseña');
  }
},

logout() {
  axios
    .post("https://api.fortunia.org/api/auth/logout")
    .then(() => {
      // Limpia el localStorage
      localStorage.removeItem("token");
      localStorage.removeItem("role");

      // Redirige al usuario a la página de inicio
      window.location.href = "/";
    })
    .catch((error) => {
      console.error("Error al cerrar sesión:", error);

      // Asegúrate de limpiar y redirigir incluso si hay un error
      localStorage.removeItem("token");
      localStorage.removeItem("role");
      window.location.href = "/";
    });
},


  },

};
</script>



  
  <style scoped>
 

 /* RESET DE ESTILOS GENERALES */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* FUENTES Y COLORES GENERALES */
body {
  font-family: 'Arial', sans-serif;
  background-color: #f4f7fc;
  color: #333;
  line-height: 1.6;
}

/* TÍTULOS Y ENCABEZADOS */
h2, h3, h4 {
  color: #2a3d66;
  font-weight: 600;
  margin-bottom: 20px;
}

h2 {
  font-size: 2rem;
  text-align: center;
  color: #1e2a47;
}

h3 {
  font-size: 1.4rem;
  margin-bottom: 10px;
}

/* CONTENEDOR PRINCIPAL */
.admin-panel {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* BOTÓN DE CIERRE DE SESIÓN */
.logout-container {
  text-align: right;
  margin-bottom: 20px;
}

.logout-button {
  background-color: #f64c72;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 0.9rem;
}

.logout-button:hover {
  background-color: #e0385c;
}

/* RESUMEN DE OPERACIONES */
.operations-summary {
  background-color: #eef2f9;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.summary-card {
  background-color: #fff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.summary-card p {
  margin: 10px 0;
}

.summary-card strong {
  font-weight: 700;
}

/* MENÚ DE ADMINISTRACIÓN */
.admin-menu {
  margin-top: 30px;
  text-align: center;
}

.admin-menu button {
  background-color: #42b983;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  margin: 10px;
  transition: background-color 0.3s ease;
}

.admin-menu button:hover {
  background-color: #36a277;
}

.admin-menu button.active {
  background-color: #2d8c6b;
}

/* FORMULARIOS Y ENTRADAS */
form {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

form div {
  margin-bottom: 15px;
}

form label {
  font-size: 1rem;
  color: #555;
}

/* Formulario de correo */
form div {
  margin-bottom: 15px;
}

form input,
form select,
form textarea {
  width: 100%;
  padding: 12px; /* Aumentamos el padding para hacerlo más cómodo */
  margin-top: 5px;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #fff;
}

form textarea {
  height: 200px; /* Aumentamos la altura del textarea */
  resize: vertical; /* Permite cambiar el tamaño verticalmente */
}

form textarea:focus {
  border-color: #42b983; /* Agrega un borde verde cuando el textarea está en foco */
  outline: none; /* Elimina el contorno por defecto */
}

form input[type="checkbox"] {
  width: auto;
}

form button {
  background-color: #42b983;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
}

form button:disabled {
  background-color: #ddd;
}

form button:hover {
  background-color: #36a277;
}

/* TARJETAS DE ORDENES */
.order-cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.order-card {
  background-color: #fff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.order-card h4 {
  font-size: 1.1rem;
  margin-bottom: 10px;
}

.order-card a {
  color: #42b983;
  text-decoration: none;
}

.order-card p {
  font-size: 0.9rem;
  color: #666;
}

.order-card button {
  background-color: #f64c72;
  color: #fff;
  padding: 8px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.order-card button:hover {
  background-color: #e0385c;
}

/* PESTAÑAS DE TABS */
.tabs {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.tabs button {
  background-color: #ffffff;
  color: #333;
  padding: 10px 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.tabs button.active {
  background-color: #42b983;
  color: #fff;
  border-color: #36a277;
}

.tabs button:hover {
  background-color: #f4f7fc;
}

/* PIE DE PÁGINA */
.footer {
  background-color: #2a3d66;
  color: #fff;
  padding: 20px;
  text-align: center;
  border-radius: 8px 8px 0 0;
  margin-top: 30px;
}

.footer p {
  margin-bottom: 10px;
}

.footer nav ul {
  list-style: none;
  padding: 0;
}

.footer nav ul li {
  display: inline;
  margin: 0 10px;
}

.footer nav ul li a {
  color: #fff;
  text-decoration: none;
  font-size: 1rem;
  transition: color 0.3s ease;
}

.footer nav ul li a:hover {
  color: #42b983;
}

/* ESTILOS RESPONSIVOS */
@media (max-width: 768px) {
  .order-cards {
    grid-template-columns: 1fr;
  }

  .tabs {
    flex-direction: column;
  }

  .tabs button {
    width: 100%;
    margin-bottom: 10px;
  }

  .admin-panel {
    padding: 15px;
  }
}

@media (max-width: 480px) {
  h2 {
    font-size: 1.5rem;
  }

  .admin-panel {
    margin: 10px;
    padding: 10px;
  }
}


/* Botón "Crear Nueva Orden" */
button.create-order {
  background-color: #42b983;
  color: #fff;
  padding: 12px 30px;
  font-size: 1.1rem;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  margin-bottom: 20px;
  display: inline-block;
  text-align: center;
}

button.create-order:hover {
  background-color: #36a277;
  transform: scale(1.05);
}

button.create-order:focus {
  outline: none;
}

/* Estilo para el formulario */
form {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

/* Botones de "Submit" y "Cancel" */
form button[type="submit"],
form button[type="button"] {
  padding: 12px 20px;
  font-size: 1rem;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  margin-right: 15px;
}

form button[type="submit"] {
  background-color: #42b983;
  color: white;
}

form button[type="submit"]:hover {
  background-color: #36a277;
  transform: scale(1.05);
}

form button[type="button"] {
  background-color: #f64c72;
  color: white;
}

form button[type="button"]:hover {
  background-color: #e0385c;
  transform: scale(1.05);
}

form button:disabled {
  background-color: #ddd;
  cursor: not-allowed;
}

/* Separación entre los botones */
form .button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

form .button-container button {
  margin-left: 10px;
}



/* Botón "Crear Nueva Orden" */
button.create-new-order {
  background-color: #42b983; /* Color verde */
  color: #fff;
  padding: 12px 30px;
  font-size: 1.1rem;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  margin-bottom: 20px;
  margin-top: 10px; /* Separación en la parte superior */
  display: inline-block;
  text-align: center;
  margin-right: 15px; /* Añadido para separarlo un poco de otros elementos si es necesario */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra para un efecto de profundidad */
}

button.create-new-order:hover {
  background-color: #36a277; /* Efecto hover más oscuro */
  transform: scale(1.05); /* Efecto de agrandado al pasar el cursor */
}

button.create-new-order:focus {
  outline: none; /* Elimina el borde de enfoque por defecto */
}

button.create-new-order:active {
  background-color: #2d8c6b; /* Efecto de presionado */
  transform: scale(1.02);
}

/* Resalta el estado activo */
button.create-new-order.active {
  background-color: #36a277;
}



</style>
