<template>
    <div class="verify-page">
      <VerifyCode />
    </div>
  </template>
  
  <script>
  import VerifyCode from "@/components/VerifyCode.vue";
  
  export default {
    components: {
      VerifyCode,
    },
  };
  </script>
  
  <style scoped>
  .verify-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  </style>
  