import { createApp } from 'vue'
import App from './App.vue'
import axios from 'axios'
import router from './router'

const app = createApp(App)

// Establecer la base URL y los encabezados de autorización
const token = localStorage.getItem('token')
if (token) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
}

axios.defaults.baseURL = 'https://api.fortunia.org'

// Montar la aplicación Vue
app.use(router).mount('#app')  // Asegúrate de usar el router en la aplicación
