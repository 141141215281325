<template>
    <div class="verify-container">
      <img src="@/assets/logo.png" alt="Logo" class="verify-logo" />
    
      <div class="verify-message">
        <h2>¡Verifica tu Teléfono!</h2>
        <p>
          Al verificar tu número telefónico recibirás todas las alertas a través de SMS.
          <br />
          Al ingresar este código, aceptas recibir notificaciones del sistema a través de SMS.
        </p>
      </div>
    
      <form @submit.prevent="verifyCode" class="verify-form">
        <label for="phoneNumber">Tu número de Teléfono</label>
        <input
          v-model="phoneNumber"
          type="text"
          placeholder="Número de teléfono"
          readonly
          disabled
        />
    
        <button
          v-if="!isCodeSent"
          type="button"
          @click="getVerificationCode"
          :disabled="isButtonDisabled"
        >
          Obtener Código
        </button>
    
        <div v-if="isCodeSent" class="verification-code-container">
          <label for="verificationCode">Ingresa el código</label>
          <input
            v-model="verificationCode"
            type="text"
            placeholder="Código de verificación"
          />
          <button type="submit">Verificar Código</button>
        </div>
      </form>


      <router-link to="/" class="back-link">← Regresar</router-link>
    
      <p v-if="errorMessage" class="error">{{ errorMessage }}</p>
      <p v-if="successMessage" class="success">{{ successMessage }}</p>
    
      <footer class="footer">
        <p>Fortunia 2024 Todos los Derechos Reservados</p>
        <p>Creado por <strong>Coss Consulting Group</strong></p>
      </footer>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    data() {
      return {
        phoneNumber: "",
        verificationCode: "",
        errorMessage: "",
        successMessage: "",
        isCodeSent: false,
        isButtonDisabled: false,
      };
    },
    mounted() {
      // Verificar si el teléfono ya está verificado cuando la página se carga
      this.checkPhoneVerification();
      this.fetchPhoneNumber();
    },
    methods: {
      // Verificar si el teléfono está verificado
      async checkPhoneVerification() {
        const token = localStorage.getItem("token");
        try {
          // Realizamos la solicitud al backend para verificar si el teléfono está verificado
          const response = await axios.get("https://api.fortunia.org/api/auth/check-phone-verification", {
            headers: {
              Authorization: `Bearer ${token}`, // Pasamos el token en los headers
            },
          });
  
          // Si el teléfono está verificado, redirigir al inicio
          if (response.data.phoneVerified) {
            this.$router.push("/"); // Redirigir al usuario a la página principal
          }
        } catch (error) {
          console.error("Error al verificar el teléfono:", error);
        }
      },
  
      async fetchPhoneNumber() {
        const token = localStorage.getItem("token");
        try {
          const response = await axios.get("https://api.fortunia.org/api/users/phone", {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          this.phoneNumber = response.data.phoneNumber;
        } catch (error) {
          this.errorMessage = "No se pudo obtener el número de teléfono.";
        }
      },
  
      async getVerificationCode() {
        const token = localStorage.getItem("token");
        try {
          this.isButtonDisabled = true;
          setTimeout(() => {
            this.isButtonDisabled = false;
          }, 12000); // 2 minutos en milisegundos
  
          await axios.post(
            "https://api.fortunia.org/api/auth/send-verification-code",
            {},
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          this.isCodeSent = true;
        } catch (error) {
          this.errorMessage = "Error al obtener el código de verificación.";
        }
      },
  
      async verifyCode() {
        this.errorMessage = "";
        this.successMessage = "";
  
        const token = localStorage.getItem("token");
        try {
          const response = await axios.post(
            "https://api.fortunia.org/api/auth/verify-phone",
            {
              code: this.verificationCode,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
  
          if (response.data.message === "Número de teléfono verificado exitosamente.") {
            this.successMessage = "¡Verificación exitosa! Redirigiendo al inicio...";
            setTimeout(() => {
              this.$router.push("/"); // Redirigir al usuario
            }, 2000);
          }
        } catch (error) {
          this.errorMessage = error.response?.data?.message || "Código de verificación incorrecto.";
        }
      },
    },
  };
  </script>
  
  
  <style scoped>
  .verify-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f4f4f4;
    padding: 20px;
  }
  
  .verify-logo {
    width: 150px;
    margin-bottom: 30px;
  }
  
  .verify-message {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .verify-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 400px;
  }
  
  input {
    margin-bottom: 20px;
    padding: 12px;
    width: 100%;
    max-width: 300px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #e9e9e9;
    font-size: 16px;
  }
  
  button {
    padding: 14px 20px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    max-width: 300px;
    font-size: 16px;
  }
  
  button:hover {
    background-color: #45a049;
  }
  
  button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .verification-code-container {
    width: 100%;
    max-width: 300px;
  }
  
  .error {
    color: red;
    margin-top: 20px;
    font-size: 14px;
  }
  
  .success {
    color: green;
    margin-top: 20px;
    font-size: 14px;
  }
  
  .footer {
    background-color: #f1f1f1;
    padding: 20px;
    text-align: center;
    font-size: 14px;
    color: #333;
    margin-top: 20px;
  }
  
  @media (max-width: 768px) {
    .verify-logo {
      width: 120px;
    }
  
    .verify-form {
      padding: 10px;
    }
  
    input,
    button {
      padding: 10px;
      font-size: 14px;
    }
  }


  /* Enlace para atras */
  .back-link {
  margin-top: 20px;
  text-decoration: none;
  color: #4caf50;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
}

.back-link:hover {
  text-decoration: underline;
}

  </style>
  