<template>
  <div class="register-container">
    <div class="register-box">
      <h2>Registro</h2>
      <form @submit.prevent="handleRegister" class="register-form">
        <input v-model="name" type="text" placeholder="Nombre de usuario" required />
        <input v-model="email" type="email" placeholder="Correo electrónico" required />
        <input v-model="password" type="password" placeholder="Contraseña" required />
        <input v-model="phoneNumber" type="text" placeholder="Teléfono Formato Internacional" required />

        <div v-if="phoneNumber && !isPhoneNumberValid" class="error">Número de teléfono inválido.</div>

        <div class="notification">
          <label>
            <input 
              type="checkbox" 
              v-model="receiveNotifications" 
              :disabled="!isPhoneNumberValid"
            />
            Recibirás las señales del sistema a través de SMS
          </label>
        </div>
        
        <button type="submit" class="submit-btn" :disabled="isSubmitting || !isFormValid">Registrarse</button>
      </form>

      <p v-if="isSubmitting" class="info">Registrando...</p>
      <p v-if="successMessage" class="success">{{ successMessage }}</p>
      <p v-if="errorMessage" class="error">{{ errorMessage }}</p>

      <div class="footer-links">
        <p>¿Ya tienes cuenta? <a href="/">Iniciar sesión</a></p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      name: "",
      email: "",
      password: "",
      phoneNumber: "",
      receiveNotifications: false,
      errorMessage: "",
      successMessage: "",
      isSubmitting: false, // Estado para mostrar el mensaje de registrando
      isPhoneNumberValid: true, // Estado para validación del número de teléfono
    };
  },
  computed: {
    isFormValid() {
      return this.name && this.email && this.password && this.isPhoneNumberValid;
    }
  },
  methods: {
    validatePhoneNumber() {
      // Validamos el número si no está vacío
      if (this.phoneNumber) {
        const phoneRegex = /^[\d+\-() \s]+$/;
        this.isPhoneNumberValid = phoneRegex.test(this.phoneNumber);
      } else {
        this.isPhoneNumberValid = false; // Si el teléfono está vacío, es inválido
      }
    },
    async handleRegister() {
      // Validar teléfono antes de enviar
      this.validatePhoneNumber();

      // Si el número de teléfono no es válido, no permitimos enviar el formulario
      if (!this.isPhoneNumberValid) {
        this.errorMessage = "Por favor ingresa un número de teléfono válido.";
        return;
      }

      this.errorMessage = "";
      this.successMessage = "";
      this.isSubmitting = true; // Activamos el estado de "registrando..."

      try {
        const response = await axios.post("https://api.fortunia.org/api/auth/register", {
          name: this.name,
          email: this.email,
          password: this.password,
          phone_number: this.phoneNumber,
          receive_sms_alerts: false,
        });

        this.successMessage = response.data.message;

        // Limpiar el formulario si el registro es exitoso
        this.name = "";
        this.email = "";
        this.password = "";
        this.phoneNumber = "";
        this.receiveNotifications = false;
      } catch (error) {
        this.errorMessage = error.response?.data?.message || "Ocurrió un error. Inténtalo de nuevo.";
      } finally {
        this.isSubmitting = false; // Desactivamos el estado de "registrando..."
      }
    },
  },
  watch: {
    phoneNumber() {
      this.validatePhoneNumber();
    }
  },
};
</script>

<style scoped>
/* Estilos para la pantalla de registro */
.register-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f9f9f9;
}

.register-box {
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  text-align: center;
}

h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

.register-form {
  display: flex;
  flex-direction: column;
}

input {
  margin-bottom: 15px;
  padding: 12px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 5px;
  transition: border-color 0.3s;
}

input:focus {
  border-color: #4caf50;
  outline: none;
}

button {
  padding: 14px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #45a049;
}

.notification {
  margin-bottom: 20px;
  text-align: left;
}

.footer-links {
  margin-top: 15px;
}

.footer-links a {
  color: #007bff;
  text-decoration: none;
}

.footer-links a:hover {
  text-decoration: underline;
}

.success {
  color: #28a745;
  margin-top: 15px;
}

.error {
  color: #dc3545;
  margin-top: 15px;
}

/* Hacer la página adaptable */
@media (max-width: 600px) {
  .register-box {
    padding: 20px;
    width: 90%;
  }

  input {
    width: 100%;
  }

  button {
    width: 100%;
  }
}
</style>
