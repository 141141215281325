<template>
    <div class="home-page">


    <!-- Mostrar mensaje si el teléfono no está verificado -->
    <div v-if="!isPhoneVerified" class="alert-message">
      <p>
        Las señales de alerta se envían por SMS. Verifica tu número de teléfono para comenzar a recibir las alertas del sistema.
        <a href="/verify" class="verify-link">Verificar ahora</a>
      </p>
    </div>


  <!-- Mostrar el mensaje si la suscripción está vencida -->
<div v-if="showExpiredMessage" class="subscription-expired-message">
  <h2 class="expired-title">¡Tu acceso premium ha expirado!</h2>
  <p>Tu acceso finalizó el: <strong>{{ formattedExpirationDate }}</strong>.</p>
  <p>Para seguir disfrutando de nuestros beneficios exclusivos, renueva tu acceso premium hoy:</p>

  <div class="subscription-options">
    <div class="option" @click="createSubscription(30)">

      <h3>30 días (Más Popular)</h3>
      <p>$29.00 USD</p>
    </div>

    <div class="option" @click="createSubscription(365)">
      <h3>1 Año </h3>
      <p>$249.00 USD (Ahorras 99 USD)</p>
    </div>
    
  </div>
  <p class="contact-message">Contacta con el administrador para renovar tu acceso.</p>
</div>




    <!-- Mostrar la fecha de expiración si la suscripción está activa -->
    <div v-else-if="premiumExpirationDate && !isSubscriptionExpired" class="premium-expiration">
      <p>Tu acceso premium finalizará el: <strong>{{ formattedExpirationDate }}</strong></p>
    </div>



    <!-- Botón para abrir el menú de opciones de cuenta -->
<div class="account-menu-container">
  <button @click="toggleMenu" class="account-menu-button">Opciones de Cuenta</button>
  <div v-if="menuOpen" class="account-menu">
    <ul>
      <li><button @click="logout" class="menu-option">Cerrar Sesión</button></li>
      <li><button @click="showChangePassword" class="menu-option">Cambiar Contraseña</button></li>
      <li><button @click="requestAccountClosure" class="menu-option">Solicitar Cierre de Cuenta</button></li>
      
    </ul>
  </div>
</div>





    <!-- Formulario para cambiar la contraseña (solo si se selecciona) -->
    <div v-if="showPasswordForm" class="account-actions">
      <h3>Cambiar Contraseña</h3>
      <form @submit.prevent="changePassword">
        <label for="oldPassword">Contraseña Actual</label>
        <input type="password" v-model="oldPassword" required />

        <label for="newPassword">Nueva Contraseña</label>
        <input type="password" v-model="newPassword" required />

        <label for="confirmPassword">Confirmar Nueva Contraseña</label>
        <input type="password" v-model="confirmPassword" required />

        <button type="submit" class="action-button">Actualizar Contraseña</button>
        <button type="button" @click="cancelChangePassword" class="cancel-button">Cancelar</button>
   
      </form>
    </div>

    


      <h2>Lista de Señales de Operaciones</h2>

    <!-- Resumen de operaciones -->
    <div class="operations-summary">
      <h3>Resumen de Operaciones</h3>
      <div class="summary-card">
        <p>Operaciones Ganadas (Closed): <strong>{{ closedOrders.length }}</strong></p>
        <p>Operaciones Perdidas (Terminadas): <strong>{{ lostOrders.length }}</strong></p>
        <p>Operaciones Abiertas (Open): <strong>{{ openOrders.length }}</strong></p>
        <p>Promedio (Ganadas/Perdidas): 
          <strong>{{ calculateWinLossRatio }}</strong>
        </p>
      </div>
    </div>


      <!-- Contenedor de Pestañas -->
      <div class="tabs">
      <button @click="activeTab = 'open'" :class="{ active: activeTab === 'open' }">Operaciones Abiertas</button>
      <button @click="activeTab = 'closed'" :class="{ active: activeTab === 'closed' }">Operaciones Ganadas</button>
      <button @click="activeTab = 'lost'" :class="{ active: activeTab === 'lost' }">Operaciones Perdidas</button>
      </div>

  
      <!-- Mostrar órdenes abiertas -->
      <div v-if="activeTab === 'open'">

      <div v-if="openOrders.length === 0">No hay operaciones abiertas disponibles</div>
      <div v-if="openOrders.length > 0">
        <h3>Operaciones Abiertas</h3>
        <div class="order-cards">
          <div v-for="order in openOrders" :key="order.id" class="order-card">
            <h4>
        <!-- Enlace al activo en Finviz -->
        <a 
          :href="'https://finviz.com/quote.ashx?t=' + order.asset" 
          target="_blank" 
          rel="noopener noreferrer">
          {{ order.asset }}
        </a> 
        - {{ order.status }}
      </h4>

      <p v-if="order.company_name" class="company-name">
            {{ order.company_name }}
      </p>
            <p>Tipo: {{ order.type }}</p>
            <p>Precio Min de Entrada: {{ order.entry_min }}</p>
            <p>Precio Max de Entrada: {{ order.entry_max }}</p>
            <p>Salida en Perdida: {{ order.stop_loss }}</p>
            <p>Precio Objetivo 1 (+0.20): {{ order.target_price_1 }}</p>
            <p>Precio Objetivo 2 (+0.30): {{ order.target_price_2 }}</p>
            <p>Fecha de Iniciada: {{ formatDate(order.start_date) }}</p>
  
            <p v-if="order.comment">Comentarios de la Operación: {{ order.comment }}</p>
          </div>
        </div>
      </div>

    </div>
  
      <!-- Mostrar órdenes cerradas con ganancia -->
      <div v-if="activeTab === 'closed'">
      
      <div v-if="closedOrders.length > 0">
        <h3>Operaciones Cerradas con Ganancia</h3>
        <div class="order-cards">
          <div v-for="order in closedOrders" :key="order.id" class="order-card">
            <h4>
        <!-- Enlace al activo en Finviz -->
        <a 
          :href="'https://finviz.com/quote.ashx?t=' + order.asset" 
          target="_blank" 
          rel="noopener noreferrer">
          {{ order.asset }}
        </a> 
        - {{ order.status }}
      </h4>

      <p v-if="order.company_name" class="company-name">
            {{ order.company_name }}
      </p>

            <p>Tipo: {{ order.type }}</p>
            <p>Precio Min de Entrada: {{ order.entry_min }}</p>
            <p>Precio Max de Entrada: {{ order.entry_max }}</p>
            <p>Salida en Perdida: {{ order.stop_loss }}</p>
            <p>Precio Objetivo 1 (+0.20): {{ order.target_price_1 }}</p>
            <p>Precio Objetivo 2 (+0.30): {{ order.target_price_2 }}</p>
            <p>Fecha de Iniciada: {{ formatDate(order.start_date) }}</p>
            <p>Fecha de Ejecucion: {{ formatDate(order.end_date) }}</p>
  
            <p v-if="order.comment">Comentarios de la Operación: {{ order.comment }}</p>
          </div>
        </div>
      </div>

  </div>


      <!-- Mostrar operaciones terminadas (perdidas) -->
      <div v-if="activeTab === 'lost'">

      <div v-if="lostOrders.length > 0">
        <h3>Operaciones Perdidas</h3>
        <div class="order-cards"><div class="option"></div>
          <div v-for="order in lostOrders" :key="order.id" class="order-card">
            <h4>
        <!-- Enlace al activo en Finviz -->
        <a 
          :href="'https://finviz.com/quote.ashx?t=' + order.asset" 
          target="_blank" 
          rel="noopener noreferrer">
          {{ order.asset }}
        </a> 
        - {{ order.status }}
      </h4>

      <p v-if="order.company_name" class="company-name">
            {{ order.company_name }}
      </p>
            <p>Tipo: {{ order.type }}</p>
            <p>Precio Min de Entrada: {{ order.entry_min }}</p>
            <p>Precio Max de Entrada: {{ order.entry_max }}</p>
            <p>Salida en Perdida: {{ order.stop_loss }}</p>
            <p>Precio Objetivo 1 (+0.20): {{ order.target_price_1 }}</p>
            <p>Precio Objetivo 2 (+0.30): {{ order.target_price_2 }}</p>
            <p>Fecha de Iniciada: {{ formatDate(order.start_date) }}</p>
            <p>Fecha de Perdida: {{ formatDate(order.end_date) }}</p>
  
            <p v-if="order.comment">Comentarios de la Operación: {{ order.comment }}</p>
          </div>
        </div>
      </div>
    </div>




     <!-- Pie de página -->
     <footer class="footer">
      <p>Fortunia 2024 Todos los Derechos Reservados</p>
      <p>Creado por <strong>Coss Consulting Group</strong></p>
      <nav>
        <ul>
          <li><a href="/privacy.html">Política de Privacidad</a></li>
          <li><a href="/disclaimer.html">Disclaimers</a></li>
          <li><a href="/about.html">Acerca de</a></li>
        </ul>
      </nav>
    </footer>


   </div> 
 
  </template>
  
  
  <script>
  import axios from "axios";
  import moment from "moment";

  export default {
    data() {
      return {
        openOrders: [],
        closedOrders: [],
        lostOrders: [], 
        activeTab: 'open',
        menuOpen: false, // Estado del menú desplegable
        showPasswordForm: false, // Estado para mostrar el formulario de cambio de contraseña
        oldPassword: "",
        isPhoneVerified: false,
        newPassword: "",
        confirmPassword: "", 
        premiumExpirationDate: null,
        showExpiredMessage: false,
        formattedExpirationDate: '',
      };
    },
    computed: {

    // Verifica si la suscripción está vencida
    isSubscriptionExpired() {
         if (!this.premiumExpirationDate) return true;
        const now = moment();
         const expirationDate = moment(this.premiumExpirationDate);
     return now.isAfter(expirationDate); // Devuelve true si la membresía está vencida
    },


  calculateWinLossRatio() {
    if (this.closedOrders.length === 0 && this.lostOrders.length === 0) {
      return "N/A";
    }
    return (this.closedOrders.length / (this.lostOrders.length || 1)).toFixed(2);
  },

 
  },
    created() {
          this.fetchPremiumExpiration();  
          this.checkPhoneVerification();        
    },
    methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen; // Cambiar el estado del menú
    },
    showChangePassword() {
      this.showPasswordForm = true; // Mostrar el formulario de cambio de contraseña
    },

    cancelChangePassword() {
      this.showPasswordForm = false; // Ocultar el formulario
      this.oldPassword = "";
      this.newPassword = "";
      this.confirmPassword = ""; // Limpiar los campos
    },

    verifyPhoneNumber() {
    window.open("https://www.fortunia.org/verify", "_blank"); // Abrir el enlace en una nueva pestaña
  },


async fetchOrders() {
  try {
    // Intentar obtener las órdenes
    const response = await axios.get("https://api.fortunia.org/api/operations", {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });

    if (response.data && Array.isArray(response.data)) {
      // Si hay órdenes, procesarlas
      this.openOrders = response.data
        .filter((order) => order.status === "open")
        .sort((a, b) => new Date(b.start_date) - new Date(a.start_date));

      this.closedOrders = response.data
        .filter((order) => order.status === "closed")
        .sort((a, b) => {
          const endDateDiff = new Date(b.end_date) - new Date(a.end_date);
          if (endDateDiff !== 0) return endDateDiff; 
          return new Date(b.start_date) - new Date(a.start_date);
        });

    } 

    // Obtener y ordenar las órdenes terminadas (perdidas)
    const lostResponse = await axios.get("https://api.fortunia.org/api/operations/terminada", {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });

    if (lostResponse.data && Array.isArray(lostResponse.data)) {
      this.lostOrders = lostResponse.data.sort((a, b) => {
        const endDateDiff = new Date(b.end_date) - new Date(a.end_date);
        if (endDateDiff !== 0) return endDateDiff;
        return new Date(b.start_date) - new Date(a.start_date);
      });
    }

  } catch (error) {
    console.error("Error fetching orders", error);
    alert("Unable to fetch orders. Please try again later.");
  }
},


async checkPhoneVerification() {
      const token = localStorage.getItem("token");
      try {
        // Verificar si el teléfono está verificado
        const response = await axios.get("https://api.fortunia.org/api/auth/check-phone-verification", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // Actualizar el estado de verificación
        this.isPhoneVerified = response.data.phoneVerified;
      } catch (error) {
        console.error("Error al verificar el teléfono:", error);
        // En caso de error, asumir que el teléfono no está verificado
        this.isPhoneVerified = false;
      }
    },


async fetchPremiumExpiration() {
  try {
    const response = await axios.get("https://api.fortunia.org/api/update-users/premium-expiration", {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });

    // Verifica si la respuesta está vacía, nula o el estado es 400
    if (!response.data || response.status === 400 || !response.data.expirationDate) {
      console.warn('El usuario no tiene acceso premium asignado.');
      this.premiumExpirationDate = null;
      this.formattedExpirationDate = '';
      this.showExpiredMessage = true; // Muestra el mensaje de que no tiene acceso premium
      return; // Sal de la función
    }

    // Si la respuesta es válida y contiene una fecha de expiración
    this.premiumExpirationDate = response.data.expirationDate;
    this.formattedExpirationDate = moment(this.premiumExpirationDate).format('YYYY-MM-DD HH:mm:ss');
    console.log('Fecha de expiración:', this.premiumExpirationDate);

    if (!this.isSubscriptionExpired) {
      this.fetchOrders();
      this.showExpiredMessage = false;
    } else {
      this.showExpiredMessage = true;
    }
  } catch (error) {
    console.error("Error fetching premium expiration date", error);
    this.premiumExpirationDate = null;
    this.formattedExpirationDate = '';
    this.showExpiredMessage = true; // En caso de error, asume que no tiene acceso premium
  }
},



      formatDate(date) {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        return new Date(date).toLocaleDateString('en-US', options);
      },


 logout() {
  axios
    .post("https://api.fortunia.org/api/auth/logout")
    .then(() => {
      // Limpia el localStorage
      localStorage.removeItem("token");
      localStorage.removeItem("role");

      // Redirige al usuario a la página de inicio
      window.location.href = "/";
    })
    .catch((error) => {
      console.error("Error al cerrar sesión:", error);

      // Asegúrate de limpiar y redirigir incluso si hay un error
      localStorage.removeItem("token");
      localStorage.removeItem("role");
      window.location.href = "/";
    });
},

async changePassword() {
      if (this.newPassword !== this.confirmPassword) {
        alert("Las contraseñas no coinciden.");
        return;
      }

      try {
        const response = await axios.post("https://api.fortunia.org/api/auth/change-password", {
          oldPassword: this.oldPassword,
          newPassword: this.newPassword,
        });

        if (response.data.success) {
          alert("Contraseña cambiada exitosamente.");
          this.showPasswordForm = false;
        } else {
          alert("Error al cambiar la contraseña.");
        }
      } catch (error) {
        console.error("Error changing password", error);
        alert("Ocurrió un error. Por favor intente de nuevo.");
      }
    },





  requestAccountClosure() {
  fetch('https://api.fortunia.org/api/users/request-account-closure', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`, // Asegúrate de agregar el token de autenticación
    },
  })
  .then(response => response.json())
  .then(data => {
    if (data.message) {
      alert(data.message); // Muestra el mensaje de éxito
    }
  })
  .catch(error => {
    console.error('Error al solicitar el cierre de cuenta:', error);
    alert('Ocurrió un error al procesar la solicitud.');
  });
},

async createSubscription(period) {
    try {
      // Realizar la solicitud para crear la sesión de Stripe con el período elegido
      const response = await axios.post("https://api.fortunia.org/api/stripe/create-subscription", 
        { period },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}` // Asegúrate de enviar el token de autenticación
          }
        }
      );

      // Redirigir al usuario a la URL de la sesión de Stripe
      window.location.href = response.data.url;
    } catch (error) {
      console.error("Error al crear la sesión de suscripción:", error);
      alert("Hubo un error al procesar la suscripción. Por favor, intenta de nuevo.");
    }
  },


  },
};
</script>




  
  
<style scoped>

/* General */
body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f7f6;
  color: #333;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

h1, h2, h3, h4 {
  color: #333;
}

strong {
  font-weight: bold;
}

/* Contenedor principal */
.home-page {
  padding: 20px;
}

/* Estilo para el mensaje de expiración del acceso premium */
/* Estilo general del mensaje */
.subscription-expired-message {
  background: linear-gradient(135deg, #ffebee, #ffcdd2);
  border: 1px solid #f44336;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  font-family: 'Roboto', sans-serif;
  color: #333;
}

.subscription-expired-message h2 {
  color: #d32f2f;
  font-size: 24px;
  margin-bottom: 10px;
}

.subscription-expired-message p {
  font-size: 16px;
  margin-bottom: 15px;
}

.subscription-expired-message strong {
  color: #b71c1c;
}

/* Opciones de suscripción */
.subscription-options {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 15px;
}

.option {
  background-color: #ffffff;
  border: 1px solid #f44336;
  border-radius: 8px;
  padding: 10px 20px;
  text-align: center;
  flex: 1;
  min-width: 100px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.option:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.option h3 {
  font-size: 18px;
  color: #d32f2f;
  margin-bottom: 5px;
}

.option p {
  font-size: 14px;
  color: #757575;
}

/* Mensaje de contacto */
.contact-message {
  font-size: 14px;
  color: #d32f2f;
  margin-top: 15px;
}


/* Estilos para el menú de opciones de cuenta */
.account-menu-container {
  position: relative;
  display: inline-block;
}

.account-menu-button {
  background-color: #42b983;
  color: white;
  padding: 12px 25px;
  font-size: 1rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}

.account-menu-button:hover {
  background-color: #36a277;
}

.account-menu {
  position: absolute;
  top: 45px;
  right: 0;
  background-color: white;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 10px;
  width: 200px;
}

.menu-option {
  background-color: transparent;
  border: none;
  padding: 10px;
  width: 100%;
  text-align: left;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.menu-option:hover {
  background-color: #f1f1f1;
}

.menu-option:focus {
  outline: none;
}

/* Formulario de cambio de contraseña */
.account-actions {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.account-actions h3 {
  margin-bottom: 15px;
  color: #333;
}

.account-actions form {
  display: grid;
  grid-gap: 10px;
}

.account-actions label {
  font-weight: bold;
}

.account-actions input {
  padding: 12px;
  border-radius: 6px;
  border: 1px solid #ddd;
  font-size: 1rem;
}

.account-actions button {
  background-color: #42b983;
  color: white;
  padding: 12px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 1rem;
}

.account-actions button:hover {
  background-color: #36a277;
}

.cancel-button {
  background-color: #f1f1f1;
  color: #333;
  margin-left: 10px;
}

.cancel-button:hover {
  background-color: #ddd;
}

/* Resumen de operaciones */
.operations-summary {
  margin-top: 30px;
}

.summary-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-top: 20px;
}

.summary-card p {
  font-size: 1.1rem;
}

/* Pestañas de operaciones */
.tabs {
  display: flex;
  justify-content: space-around;
  margin-top: 30px;
  border-bottom: 2px solid #ddd;
}

.tabs button {
  background-color: transparent;
  border: none;
  padding: 10px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: color 0.3s ease, border-bottom 0.3s ease;
}

.tabs button.active {
  color: #42b983;
  border-bottom: 2px solid #42b983;
}

.tabs button:hover {
  color: #36a277;
}

/* Estilos para las tarjetas de orden */
.order-cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  margin-top: 20px;
}

.order-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.order-card h4 a {
  color: #42b983;
  text-decoration: none;
}

.order-card h4 a:hover {
  text-decoration: underline;
}

.order-card .company-name {
  font-weight: bold;
  margin-top: 5px;
  font-size: 1rem;
}

/* Pie de página */
.footer {
  background-color: #333;
  color: #fff;
  padding: 20px;
  text-align: center;
  margin-top: 50px;
}

.footer p {
  margin: 0;
}

.footer nav ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  justify-content: center;
}

.footer nav ul li {
  margin: 0 15px;
}

.footer nav ul li a {
  color: #fff;
  text-decoration: none;
}

.footer nav ul li a:hover {
  text-decoration: underline;
}

/* Responsividad */
@media screen and (max-width: 768px) {
  .order-cards {
    grid-template-columns: 1fr 1fr;
  }

  .tabs {
    flex-direction: column;
    align-items: center;
  }

  .order-cards {
    grid-template-columns: 1fr;
  }

  .summary-card {
    padding: 15px;
  }
}

@media screen and (max-width: 480px) {
  .tabs {
    flex-direction: column;
    align-items: stretch;
  }

  .order-cards {
    grid-template-columns: 1fr;
  }

  .account-actions {
    padding: 15px;
  }

  .account-menu-button {
    width: 100%;
    margin-bottom: 10px;
  }

  .footer nav ul {
    flex-direction: column;
  }
}


/* Alerta de Telefono no verifica */


.alert-message {
  background-color: #ffeb3b;
  color: #000;
  padding: 15px;
  text-align: center;
  border: 1px solid #fbc02d;
  margin-bottom: 20px;
}

.alert-message p {
  margin: 0;
}

.alert-message .verify-link {
  font-weight: bold;
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
}

.home-content {
  padding: 20px;
}

</style>