<!-- src/views/OperationsPage.vue -->
<template>
    <div>
      <h1>Operaciones</h1>
      <p>Lista de operaciones activas.</p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'OperationsPage'
  }
  </script>
  